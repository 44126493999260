<template>
	<div data-route class="page">
		<div class="page__info">
			<div class="page__info--title">
				Reset password
			</div>
		</div>

		<div data-element="main">
			<validation-observer
				data-element="form"
				tag="form"
				ref="form"
				@submit.prevent="submit"
			>
				<input-field
					name="Email address"
					type="email"
					autocomplete="username"
					rules="email|required"
					v-model="credentials.email"
				/>
				<actions :actions="actions" />
			</validation-observer>
		</div>
	</div>
</template>

<route>
	{
		"meta": {
			"isPublicOnly": true
		}
	}
</route>

<script>
	import InputField          from '@/components/forms/InputField';
	import Actions             from '@/components/ui/Actions';
	import api                 from '@/services/api';
	import actionClick         from '@/mixins/actionClick';
	import invalidFormMessage  from '@/mixins/invalidFormMessage';
	import { supportEmail }    from '@/consts';

	export default {
		layout: 'v2default',
		metaInfo: {
			title: 'Reset password'
		},
		components: {
			InputField,
			Actions
		},
		mixins: [actionClick, invalidFormMessage],
		data: () => ({
			credentials: {
				email: ''
			},
			actions: {
				primary: [
					{
						text: 'Reset password',
						type: 'submit'
					},
					{
						text: 'Cancel',
						route: '/login'
					}
				],
				secondary: [
					{
						text: 'Contact support',
						url: supportEmail
					}
				]
			}
		}),
		computed: {
			getBreadcrumbs () {
				return [
					{
						path: '/reset-password',
						text: 'Reset password'
					}
				];
			}
		},
		created () {
			this.$store.commit('ui/setBreadcrumbs', {
				breadcrumbs: this.getBreadcrumbs
			});
			this.$store.commit('ui/setLoadingIsHidden');
		},
		methods: {
			toggleMenu () {
				this.menuOpen = !this.menuOpen;
			},
			async submit () {
				const valid = await this.$refs.form.validate();
				if (!valid) {
					this.showInvalidFormMessage();
					return;
				}
				const response = await api.auth.resetPassword({
					credentials: this.credentials
				});
				if (!response) {
					return;
				}
				this.$store.commit('ui/showNotification', {
					notification: 'Check your emails to continue '
				});
			}
		}
	};

</script>

<style lang="scss" scoped>

.page {
  &__info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;

    &--title {
      font-size: 46px;
      margin-bottom: 24px;
    }
  }

  @media (max-width: 768px) {
    ::v-deep [data-component="actions"] {
      flex-direction: column;
      gap: 12px;

      [data-element="actions-primary"] {
        width: 100%;
        justify-content: space-between;

        [data-component="btn"] {
          margin: 0 !important;
        }
      }

      [data-element="actions-secondary"] {
        width: 100%;

        [data-component="btn"] {
          width: 100%;
          margin: 0 !important;
        }
      }
    }
  }

}

</style>
